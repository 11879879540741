/* ./src/index.css */
body {
  margin: 0;
  font-family: Raleway, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #98afb5;
  background-image: url("./assets/images/zurich.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: right bottom;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li {
  background-image: url("./assets/images/bullet.png");
  background-repeat: no-repeat;
  padding-left: 30px;
  line-height: 20px;
  margin-bottom:10px;
}

blockquote  {
  color: #484848;
  padding-left: 1.5em;
  border-left: 4px solid #ebebeb;
  margin-left: 18px;
}
